import React, { useState } from "react";
import "../css/App.css";
import { postSender } from "../json/RequestSender.js";
import { SaleReconciliationRequest } from "../json/SaleReconciliationRequest.js";
import { London_SaleReconciliation_URL } from "../js/LondonServerInfo.js";
import { Ottawa_SaleReconciliation_URL } from "../js/OttawaServerInfo.js";
import { Form } from "react-bootstrap";
import { Test_SaleReconciliation_URL } from "../js/TestServer.js";

let reconRequest = SaleReconciliationRequest;

const SaleReconiliationForm = () => {
	const initPartyID = localStorage.getItem("partyID");
	const initTransactionReference = SaleReconciliationRequest.RcncltReq.Tx.SaleTxId.TxRef;
	const initSaleReconID = SaleReconciliationRequest.RcncltReq.Tx.SaleRcncltId;

	const [partyID, setPartyID] = useState(initPartyID);
	const [TransactionReference, setTransactionReference] = useState(initTransactionReference);
	const [SaleReconID, setSaleReconcilationID] = useState(initSaleReconID);
	const [displayText, setDisplayText] = useState("Initial Text");

	// 0 - Test 1 - london 2 - ottawa
	const [server, setServer] = useState(0); 

	const handleRadioChange = (event) => {
		setServer(event.target.value);
	};

	const handleFetchData = async () => {
		console.log("Form Submitted");
		reconRequest.Hdr.InitgPty.PtyId = partyID;
		reconRequest.RcncltReq.Tx.SaleTxId.TxRef = TransactionReference;
		reconRequest.RcncltReq.Tx.SaleRcncltId = SaleReconID;
		// ****************************
		// Important to set the date before sending
		reconRequest.Hdr.CreDtTm = new Date().toISOString();
		reconRequest.RcncltReq.Tx.SaleTxId.TxDt = new Date().toISOString();
		// ****************************

		let data = "";
		try {
			if (server == 0) {
				console.log("Sent to Test: " + Test_SaleReconciliation_URL);
				data = await postSender(reconRequest, Test_SaleReconciliation_URL);
			} else if (server == 1) {
				console.log("Sent to london: " + London_SaleReconciliation_URL);
				data = await postSender(reconRequest, London_SaleReconciliation_URL);
			} else {
				console.log("Sent to Ottawa: " + Ottawa_SaleReconciliation_URL);
				data = await postSender(reconRequest, Ottawa_SaleReconciliation_URL);
			}
			if (data.error != null) {
				setDisplayText(data.error);
			} else {
				setDisplayText(data);
			}
		} catch (error) {
			setDisplayText(error);
		}
	};

	const updatePartyID = (ID) => {
		localStorage.setItem("partyID", ID);
		setPartyID(ID);
	};

	const resetValues = async () => {
		console.log(new Date());
		setTransactionReference(initTransactionReference);
		setSaleReconcilationID(initSaleReconID);
	};

	return (
		<div className="container mt-4">
			<div className="contentContainer">
				<div className="titleWrappers">
					<h1>Enter Fields for TSALE Reconciliation Request</h1>
				</div>
				<div className="inputWrapper">
					<div className="form-group">
						<div>
							<label htmlFor="ptyID">Party ID</label>
							<input type="text" id="ptyID" className="form-control mb-2" value={partyID} onChange={(e) => updatePartyID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="TxRef">Transaction Reference (Identifies this request)</label>
							<input type="text" id="TxRef" className="form-control mb-2" value={TransactionReference} onChange={(e) => setTransactionReference(e.target.value)} />
						</div>
						<div>
							<label htmlFor="SaleRcncltId">Sale Reconcilation ID (This needs to match the Reconcilation ID set in your Sale Payment Requests)</label>
							<input type="text" id="SaleRcncltId" className="form-control mb-2" value={SaleReconID} onChange={(e) => setSaleReconcilationID(e.target.value)} />
						</div>
						<button className="btn btn-secondary" onClick={resetValues}>
							Reset Values
						</button>
						<Form.Group>
							<Form.Check type="radio" label="Test Server" name="radios" value="0" checked={server == 0} onChange={handleRadioChange} />
							<Form.Check type="radio" label="London Server" name="radios" value="1" checked={server == 1} onChange={handleRadioChange} />
							<Form.Check type="radio" label="Ottawa Server" name="radios" value="2" checked={server == 2} onChange={handleRadioChange} />
						</Form.Group>
					</div>
				</div>
			</div>

			<div className="footer mt-4">
				<p>Response: {displayText}</p>
				<button className="btn btn-primary" onClick={handleFetchData}>
					Send request
				</button>
			</div>
		</div>
	);
};

export default SaleReconiliationForm;
