// ********************** Connection Details for the London Server **********************
// You can tell which server your terminal is connected to in the settings page


export const London_IP = "209.90.136.197";

// export const TSALE_URL = "http://" + London_IP + ":28014";
// This is a temporary URL for working around CORS issues when you are live you shouldnt have these issues
export const TSALE_URL = "https://cors.fvpay.ca";

export const London_SalePayment_URL = TSALE_URL + "/SalePmtReq";

export const London_SaleReconciliation_URL = TSALE_URL + "/SaleRcncltReq";

export const London_SaleIntent_URL = TSALE_URL + "/PmtIntntReq";

export const London_SaleReversal_URL = TSALE_URL + "/SaleRvslReq";
