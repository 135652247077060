// ********************** Connection Details for the Test Server **********************
// You can tell which server your terminal is connected to in the settings page

export const Test_IP = "192.168.1.101:28014";

export const TSALE_URL = "http://" + Test_IP;

export const Test_SalePayment_URL = TSALE_URL + "/SalePmtReq";

export const Test_SaleReconciliation_URL = TSALE_URL + "/SaleRcncltReq";

export const Test_SaleIntent_URL = TSALE_URL + "/PmtIntntReq";

export const Test_SaleReversal_URL = TSALE_URL + "/SaleRvslReq";
