// **************************** Pre-filled Sale Reversal request ****************************
export const SaleReversalRequest = {
	Hdr: {
		CreDtTm: "2023-12-21T12:58:34.264266", //Updated when sent
		PrtcolVrsn: "1.0",
		XchgId: 9999999999999999,
		InitgPty: {
			PtyId: "party-TsaleTest",
		},
		MsgFct: "FSRQ",
	},
	SaleRvslReq: {
        Tx: {
			SaleTxId: {
				TxDt: "2023-12-21T12:58:34.297273", //Updated when sent
				TxRef: "052",
			},
            OrgnTx: { //needs to match orginal transaction
                TxTp: "CRDP",
                SaleRefId: "sale",
                SaleTxId: { 
                    TxDt: "2023-12-21T12:58:34.297273",
                    TxRef: "052",
                }
            },
            RvslRsn: "Customer issued",
            RvsdAmt: "13.00"
        }
	}
};
