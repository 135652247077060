// ********************** Connection Details for the Ottawa Server **********************
// You can tell which server your terminal is connected to in the settings page



export const Ottawa_IP = "64.26.131.253";

// export const TSALE_URL = "http://" + Ottawa_IP + ":28014";
// This is a temporary URL for working around CORS issues when you are live you shouldnt have these issues
export const TSALE_URL = "https://cors.fvpay.ca";

export const Ottawa_SalePayment_URL = TSALE_URL + "/SalePmtReq";

export const Ottawa_SaleReconciliation_URL = TSALE_URL + "/SaleRcncltReq";

export const Ottawa_SaleIntent_URL = TSALE_URL + "/PmtIntntReq";

export const Ottawa_SaleReversal_URL = TSALE_URL + "/SaleRvslReq";