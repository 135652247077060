import React, { useState } from "react";
import "../css/App.css";
import { postSender } from "../json/RequestSender.js";
import { SalePaymentIntentRequest } from "../json/SalePaymentIntentRequest.js";
import { Ottawa_SaleIntent_URL } from "../js/OttawaServerInfo.js";
import { London_SaleIntent_URL } from "../js/LondonServerInfo.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Form } from "react-bootstrap";
import { Test_SaleIntent_URL } from "../js/TestServer.js";

let intentRequest = SalePaymentIntentRequest;

const SalePaymentIntentForm = () => {
	const initPartyID = localStorage.getItem("partyID");

	const initFrcstAmt = SalePaymentIntentRequest.PmtIntntReq.Tx.CstmrOrdr.FrcstdAmt;
	const initPOIID = SalePaymentIntentRequest.PmtIntntReq.Envt.POI.POIId;
	const initSaleReferenceID = SalePaymentIntentRequest.PmtIntntReq.Tx.SaleRefId;
	const initSaleTransactionIdentificationReference = SalePaymentIntentRequest.PmtIntntReq.Tx.SaleTxId.TxRef;

	const [partyID, setPartyID] = useState(initPartyID);
	const [frcstAmt, setFrcstAmt] = useState(initFrcstAmt);
	const [POIID, setPOIID] = useState(initPOIID);
	const [displayText, setDisplayText] = useState("Initial Text");
	const [saleReferenceID, setSaleReferenceID] = useState(initSaleReferenceID);
	const [saleTransactionIdentificationReference, setSaleTransactionIdentificationReference] = useState(initSaleTransactionIdentificationReference);

	// used to switch between london and ottawa server
	// 0 - Test 1 - london 2 - ottawa
	const [server, setServer] = useState(0); 

	const handleRadioChange = (event) => {
		setServer(event.target.value);
	};

	const handleFetchData = async () => {
		if (!validateFields()) {
			return;
		}
		console.log("Form Submitted");
		intentRequest.Hdr.InitgPty.PtyId = partyID;
		intentRequest.PmtIntntReq.Tx.CstmrOrdr.FrcstdAmt = frcstAmt;
		intentRequest.PmtIntntReq.Envt.POI.POIId = POIID;
		intentRequest.PmtIntntReq.Tx.SaleRefId = saleReferenceID;
		intentRequest.PmtIntntReq.Tx.SaleTxId.TxRef = saleTransactionIdentificationReference;

		// ****************************
		// Important to set the date before sending
		intentRequest.Hdr.CreDtTm = new Date().toISOString();
		intentRequest.PmtIntntReq.Tx.SaleTxId.TxDt = new Date().toISOString();
		// ****************************

		let data = "";
		try {
			if (server == 0) {
				console.log("Sent to Test: " + Test_SaleIntent_URL);
				data = await postSender(intentRequest, Test_SaleIntent_URL);
			} else if (server == 1) {
				console.log("Sent to london: " + London_SaleIntent_URL);
				data = await postSender(intentRequest, London_SaleIntent_URL);
			} else {
				console.log("Sent to Ottawa: " + Ottawa_SaleIntent_URL);
				data = await postSender(intentRequest, Ottawa_SaleIntent_URL);
			}
			if (data.error != null) {
				setDisplayText(data.error);
			} else {
				setDisplayText(data);
			}
		} catch (error) {
			setDisplayText(error);
		}
	};

	const resetValues = () => {
		console.log(new Date());
		// This is the default PartyID. you can have whatever party ID youd like
		setPartyID("party-TsaleTest");
		setFrcstAmt(initFrcstAmt);
		setPOIID(initPOIID);
	};

	const updatePartyID = (ID) => {
		localStorage.setItem("partyID", ID);
		setPartyID(ID);
	};

	const amountReEx = new RegExp("^([0-9]*[.]{0,1}[0-9]{0,2})$");

	const validateFields = () => {
		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		const forms = document.querySelectorAll(".form-control");
		let success = true;
		// Loop over them and prevent submission
		Array.from(forms).forEach((form) => {
			switch (form.id) {
				case "saleReferenceID":
				case "saleTransactionIdentificationReference":
				case "ptyID":
					if (form.value.length < 1 || form.value.length > 35) {
						form.className = "form-control mb-2 is-invalid";
						success = false;
					} else {
						form.className = "form-control mb-2";
					}
					break;
				case "frcstAmt":
					if (!amountReEx.test(form.value)) {
						form.className = "form-control mb-2 is-invalid";
						success = false;
					} else {
						form.className = "form-control mb-2";
					}
					break;
				case "POIID":
					if (form.value.length < 1 || form.value.length > 8) {
						form.className = "form-control mb-2 is-invalid";
						success = false;
					} else {
						form.className = "form-control mb-2";
					}
					break;
				case "saleReconcilationID":
					if (form.value.length < 1 || form.value.length > 10) {
						form.className = "form-control mb-2 is-invalid";
						success = false;
					} else {
						form.className = "form-control mb-2";
					}
					break;
				default:
					success = false;
			}
		});
		return success;
	};

	return (
		<div className="container mt-4">
			<div className="contentContainer">
				<div className="titleWrappers">
					<h1>Enter Fields for TSALE Intent Request</h1>
				</div>
				<div className="inputWrapper">
					<div className="form-group">
						<div>
							<label htmlFor="ptyID">Party ID</label>
							<input type="text" id="ptyID" className="form-control mb-2" value={partyID} onChange={(e) => updatePartyID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="frcstAmt">Forcasted amount</label>
							<input type="text" id="frcstAmt" className="form-control mb-2" value={frcstAmt} onChange={(e) => setFrcstAmt(e.target.value)} />
							<div className="invalid-feedback">Must be in format of 0.00</div>
						</div>
						<div>
							<label htmlFor="POIID">POI ID</label>
							<input type="text" id="POIID" className="form-control mb-2" value={POIID} onChange={(e) => setPOIID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 8 characters</div>
						</div>
						<div>
							<label htmlFor="saleReferenceID">Sale Reference ID</label>
							<input type="text" id="saleReferenceID" className="form-control mb-2" value={saleReferenceID} onChange={(e) => setSaleReferenceID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<div>
							<label htmlFor="saleTransactionIdentificationReference">Sale Transaction Identification Reference (This must be different for each transaction in the Batch or per Sale Reconciliation ID)</label>
							<input type="text" id="saleTransactionIdentificationReference" className="form-control mb-2" value={saleTransactionIdentificationReference} onChange={(e) => setSaleTransactionIdentificationReference(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
						<button className="btn btn-secondary" onClick={resetValues}>
							Reset Values
						</button>
						<Form.Group>
							<Form.Check type="radio" label="Test Server" name="radios" value="0" checked={server == 0} onChange={handleRadioChange} />
							<Form.Check type="radio" label="London Server" name="radios" value="1" checked={server == 1} onChange={handleRadioChange} />
							<Form.Check type="radio" label="Ottawa Server" name="radios" value="2" checked={server == 2} onChange={handleRadioChange} />
						</Form.Group>
					</div>
				</div>
			</div>

			<div className="footer mt-4">
				<p>Response: {displayText}</p>
				<button className="btn btn-primary" onClick={handleFetchData}>
					Send request
				</button>
			</div>
		</div>
	);
};

export default SalePaymentIntentForm;
