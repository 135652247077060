import React, { useState } from "react";
import "../css/App.css";
import { postSender } from "../json/RequestSender.js";
import { SaleReversalRequest } from "../json/SaleReversalRequest.js";
import { London_SaleReversal_URL } from "../js/LondonServerInfo.js";
import { Ottawa_SaleReversal_URL } from "../js/OttawaServerInfo.js";
import { Form } from "react-bootstrap";
import { Test_SaleReversal_URL } from "../js/TestServer.js";

let reversalReq = SaleReversalRequest;

const SaleReversalForm = () => {
	const initPartyID = localStorage.getItem("partyID");
    const initTransactionReference = SaleReversalRequest.SaleRvslReq.Tx.SaleTxId.TxRef;
    const initOrgnSaleRefID = SaleReversalRequest.SaleRvslReq.Tx.OrgnTx.SaleRefId;
    const initOrgnSaleTransactionID = SaleReversalRequest.SaleRvslReq.Tx.OrgnTx.SaleTxId.TxRef;
    const initOrgnSaleTransactionDateTime = SaleReversalRequest.SaleRvslReq.Tx.OrgnTx.SaleTxId.TxDt;
    const initReversalReason = SaleReversalRequest.SaleRvslReq.Tx.RvslRsn;
    const initReversalAmount = SaleReversalRequest.SaleRvslReq.Tx.RvsdAmt;

	const [partyID, setPartyID] = useState(initPartyID);
    const [orgnSaleRefID, setOrgnSaleRefID] = useState(initOrgnSaleRefID);
    const [orgnSaleTransactionID, setOrgnSaleTransactionID] = useState(initOrgnSaleTransactionID);
    const [orgnSaleTransactionDateTime, setOrgnSaleTransactionDateTime] = useState(initOrgnSaleTransactionDateTime);
    const [reversalReason, setReversalReason] = useState(initReversalReason);
    const [reversalAmount, setReversalAmount] = useState(initReversalAmount);

	const [transactionReference, setTransactionReference] = useState(initTransactionReference);
	const [displayText, setDisplayText] = useState("Initial Text");

	// 0 - Test 1 - london 2 - ottawa
	const [server, setServer] = useState(0); 

	const handleRadioChange = (event) => {
		setServer(event.target.value);
	};

	const handleFetchData = async () => {
		console.log("Form Submitted");
		reversalReq.Hdr.InitgPty.PtyId = partyID;
		reversalReq.SaleRvslReq.Tx.SaleTxId.TxRef = transactionReference;
        reversalReq.SaleRvslReq.Tx.OrgnTx.SaleRefId = orgnSaleRefID;
        reversalReq.SaleRvslReq.Tx.OrgnTx.SaleTxId.TxRef = orgnSaleTransactionID;
        reversalReq.SaleRvslReq.Tx.OrgnTx.SaleTxId.TxDt = orgnSaleTransactionDateTime;
        reversalReq.SaleRvslReq.Tx.RvslRsn = reversalReason;
        reversalReq.SaleRvslReq.Tx.RvsdAmt = reversalAmount;
		// ****************************
		// Important to set the date before sending
		reversalReq.Hdr.CreDtTm = new Date().toISOString();
		reversalReq.SaleRvslReq.Tx.SaleTxId.TxDt = new Date().toISOString();
		// ****************************

		let data = "";
		try {
			if (server == 0) {
				console.log("Sent to Test: " + Test_SaleReversal_URL);
				data = await postSender(reversalReq, Test_SaleReversal_URL);
			} else if (server == 1) {
				console.log("Sent to london: " + London_SaleReversal_URL);
				data = await postSender(reversalReq, London_SaleReversal_URL);
			} else {
				console.log("Sent to Ottawa: " + Ottawa_SaleReversal_URL);
				data = await postSender(reversalReq, Ottawa_SaleReversal_URL);
			}
			if (data.error != null) {
				setDisplayText(data.error);
			} else {
				setDisplayText(data);
			}
		} catch (error) {
			setDisplayText(error);
		}
	};

	const updatePartyID = (ID) => {
		localStorage.setItem("partyID", ID);
		setPartyID(ID);
	};

	const resetValues = async () => {
		console.log(new Date());
		setTransactionReference(initTransactionReference);
	};

	return (
		<div className="container mt-4">
			<div className="contentContainer">
				<div className="titleWrappers">
					<h1>Enter Fields for TSALE Reversal Request</h1>
				</div>
				<div className="inputWrapper">
					<div className="form-group">
						<div>
							<label htmlFor="ptyID">Party ID</label>
							<input type="text" id="ptyID" className="form-control mb-2" value={partyID} onChange={(e) => updatePartyID(e.target.value)} />
							<div className="invalid-feedback">Must be between 1 and 35 characters</div>
						</div>
                        <div>
                            <label htmlFor="TransactionReference">Transaction Reference</label>
                            <input type="text" id="TransactionReference" className="form-control mb-2" value={transactionReference} onChange={(e) => setTransactionReference(e.target.value)} />
                        </div>
                        <div>
                            <label htmlFor="OrgnSaleRefID">Original Sale Reference ID</label>
                            <input type="text" id="OrgnSaleRefID" className="form-control mb-2" value={orgnSaleRefID} onChange={(e) => setOrgnSaleRefID(e.target.value)} />
                        </div>
                        <div>
                            <label htmlFor="OrgnSaleTransactionID">Original Sale Transaction ID</label>
                            <input type="text" id="OrgnSaleTransactionID" className="form-control mb-2" value={orgnSaleTransactionID} onChange={(e) => setOrgnSaleTransactionID(e.target.value)} />
                        </div>
                        <div>
                            <label htmlFor="OrgnSaleTransactionDateTime">Original Sale Transaction Date Time</label>
                            <input type="text" id="OrgnSaleTransactionDateTime" className="form-control mb-2" value={orgnSaleTransactionDateTime} onChange={(e) => setOrgnSaleTransactionDateTime(e.target.value)} />
                        </div>
                        <div>
                            <label htmlFor="ReversalReason">Reversal Reason</label>
                            <input type="text" id="ReversalReason" className="form-control mb-2" value={reversalReason} onChange={(e) => setReversalReason(e.target.value)} />
                        </div>
                        <div>
                            <label htmlFor="ReversalAmount">Reversal Amount</label>
                            <input type="text" id="ReversalAmount" className="form-control mb-2" value={reversalAmount} onChange={(e) => setReversalAmount(e.target.value)} />
                        </div>
						<button className="btn btn-secondary" onClick={resetValues}>
							Reset Values
						</button>
						<Form.Group>
							<Form.Check type="radio" label="Test Server" name="radios" value="0" checked={server == 0} onChange={handleRadioChange} />
							<Form.Check type="radio" label="London Server" name="radios" value="1" checked={server == 1} onChange={handleRadioChange} />
							<Form.Check type="radio" label="Ottawa Server" name="radios" value="2" checked={server == 2} onChange={handleRadioChange} />
						</Form.Group>
					</div>
				</div>
			</div>

			<div className="footer mt-4">
				<p>Response: {displayText}</p>
				<button className="btn btn-primary" onClick={handleFetchData}>
					Send request
				</button>
			</div>
		</div>
	);
};

export default SaleReversalForm;
